<script lang="ts">
	import BlogPostCard from "./BlogPostCard.svelte";
	import Paginator from "./Paginator.svelte";
	import DecoratedHeadline from "./DecoratedHeadline.svelte";
	import type { ActionReturn } from "svelte/action";
	import * as Glide from "@glidejs/glide";
	import { disableGlidePerSlideAmount } from "../utils/disableGlidePerSlideAmount.js";
	import type { PublishedBlogPost } from "../../../core/schema/BlogPost.js";
	import { nbspify } from "../../../core/utils/nbspify.js";
	import { querySizeMappings } from "./Picture/querySizeMappings.js";

	export let blogPosts: PublishedBlogPost[];

	export let title = "Píšeme o skladech";

	const blogsPerPage = Math.min(3, blogPosts.length);
	const blogsPerPageLg = 2;
	const blogsPerPageSm = 1;

	let currentSlide = 1;
	let visibleSlides: number[] =
		typeof window !== "undefined" ? (window.innerWidth >= 1440 && blogPosts.length > 3 ? [1, 2, 3] : [2]) : [];

	function glide(element: HTMLDivElement): ActionReturn {
		const glideOptions = {
			type: "slider",
			startAt: 1,
			focusAt: window.innerWidth < 1440 && window.innerWidth > querySizeMappings.lg ? 1 : "center",
			perView:
				window.innerWidth < querySizeMappings.lg
					? blogsPerPageSm
					: window.innerWidth < 1440
						? blogsPerPageLg
						: blogsPerPage,
			autoplay: false,
			hoverpause: false,
			animationDuration: 650,
			animationTimingFunc: "ease-in-out",
			keyboard: true,
			gap: 10,
		} satisfies Partial<Glide.Options>;

		// eslint-disable-next-line new-cap
		const glide = new Glide.default(element, glideOptions).mount();
		glide.on("run", () => {
			currentSlide = glide.index;

			const { perView } = glide.settings;
			visibleSlides =
				blogPosts.length <= 3
					? [currentSlide + 1]
					: perView === 3
						? Array.from({ length: perView }, (_, i) => currentSlide + i)
						: [currentSlide + 1];
		});
		glide.on("resize", () => {
			currentSlide = glide.index;

			disableGlidePerSlideAmount(glide, element);
			if (window.innerWidth < querySizeMappings.lg) {
				glide.update({ perView: blogsPerPageSm, focusAt: "center" });
			} else if (window.innerWidth < 1440) {
				glide.update({ perView: blogsPerPageLg, focusAt: 1 });
			} else {
				glide.update({ perView: blogsPerPage, focusAt: "center" });
			}

			const { perView } = glide.settings;
			visibleSlides =
				blogPosts.length <= 3
					? [currentSlide + 1]
					: perView === 3
						? Array.from({ length: perView }, (_, i) => currentSlide + i)
						: [currentSlide + 1];
		});

		return {
			destroy(): void {
				glide.destroy();
			},
		};
	}
</script>

<div class="flex w-full flex-col items-center">
	<div class="flex flex-col gap-8 px-4">
		<DecoratedHeadline>{nbspify(title)}</DecoratedHeadline>
		<slot name="perex" />
	</div>

	<div class="mt-[4rem] w-full" use:glide>
		<div class="glide__track w-full overflow-hidden" data-glide-el="track">
			<div class="glide__slides flex">
				{#each blogPosts as blogPost}
					<div class="glide__slide flex items-center justify-center">
						<BlogPostCard {blogPost} />
					</div>
				{/each}
			</div>
		</div>
		<Paginator
			class="mx-auto mt-10 w-full max-w-[14.875rem] md:max-w-[27.5rem]"
			itemsLength={blogPosts.length}
			{visibleSlides}
			{currentSlide}
		/>
	</div>
</div>
